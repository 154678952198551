import React from 'react';
import { observer } from 'mobx-react';

const NoChatModule = observer(
  class NoChatModule extends React.Component {

    constructor (props) {
      super(props)
      this.state = {
        countdown: 60
      }
    }

    componentDidMount = () => {
      setInterval(() => {
        this.setState({
          countdown: this.state.countdown - 1 >= 0 ? this.state.countdown - 1 : 0
        })
      }, 1000)
    }

    render() {
      return (
        <div className="clip__chat-inner clip__chat-error">
          <p>Chat: No new messages</p>
          <span>Check back soon...</span>
        </div>
      );
    }
  }
);
export default NoChatModule
import React from 'react';
import { observer } from 'mobx-react';
import ChatMessages from '../../components/chat/messages'
import Nav from '../../stores/Nav'
import ChatRoomList from '../../components/room_list'

const SessionChat = observer(
  class SessionChat extends React.Component {

    _render_active_section = () => {
      if (Nav.active_tab?.active_room !== null && Nav.active_tab?.get_active_channel()) {
        return (
          <div className="clip__chat-message-container">
            {
              Nav.active_tab.multi_level ?
                <div className="clip__chat-title"><h4>{Nav.active_tab.get_active_channel().nice_name()}</h4></div>
              : null
            }
            <ChatMessages channel={Nav.active_tab.get_active_channel()} />
          </div>
        )
      }
      return (
        <div className="clip__chat-list">
          <ChatRoomList />
        </div>
      )
    }

    render() {
      return this._render_active_section()
    }
  }

);
export default SessionChat
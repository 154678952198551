import React from 'react';
import { observer } from 'mobx-react';
import Nav from './../../stores/Nav'
import LANGUAGE from '../../locales/locale'

const DirectoryProfile = observer(
	class DirectoryProfile extends React.Component {

		constructor (props) {
			super(props)
			this.state = {
				is_open: false
			}
		}

		_toggle_open = () => {
			this.setState({
				is_open: !this.state.is_open
			})
		}

		render() {
			const profile = Nav.active_tab.get_active_channel().recipient_profile()
			return (
				<div className="clip__chat-directory-profile" onClick={this._toggle_open}>
					<img src={profile.profile_photo} alt={profile.full_name()} />
					<h4>
						{profile.full_name()}
						<svg className={`${this.state.is_open ? "open" : ""}`} xmlns="http://www.w3.org/2000/svg" width="14" height="6" viewBox="0 0 18 8">
							<polyline fill="none" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" points="0 0 8 8 16 0" transform="translate(1)"/>
						</svg>
					</h4>
					<div className={`clip__chat-directory-profile-meta ${this.state.is_open ? "open" : ""}`}>
						{profile.first_name ? <p><b>{ LANGUAGE.FIRST_NAME }</b> {profile.first_name}</p> : null}
						{profile.last_name ? <p><b>{ LANGUAGE.LAST_NAME }</b> {profile.last_name}</p> : null}
						{profile.company_name ? <p><b>{ LANGUAGE.COMPANY_NAME }</b> {profile.company_name}</p> : null}
					</div>
				</div>
			)
		}
	}
);
export default DirectoryProfile
/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Chat from './../Chat'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Logger from '../Logger'
import PlatformApi, { API_DELETE_ERROR } from '../../api/PlatformApi'
import LANGUAGE from '../../locales/locale'
dayjs.extend(relativeTime)

const Message = types
	.model('Message', {
		timetoken: types.identifier,
		text: types.optional(types.string, ""),
		first_name: types.optional(types.string, ""),
		last_name: types.optional(types.string, ""),
		channel: types.maybeNull(types.string, ""), // This is the channel UUID
		uuid: types.optional(types.string, ""),// This is the user UUID
		publisher: types.maybeNull(types.string),
		is_deleting: types.optional(types.boolean, false),
		is_deleted: types.optional(types.boolean, false),
		is_mod: types.optional(types.boolean, false)
	})
	.actions(self => ({
		remove_message: flow(function* () {
			Logger.log("Message:remove_message", self.timetoken, self.channel)
			self.is_deleting = true
			if(Chat.current_user.is_moderator() || Chat.current_user.is_rep()){
				const deleted = yield PlatformApi.delete_message(self.timetoken, self.channel)
				if(deleted !== API_DELETE_ERROR && deleted?.status === "success"){
					self.set_deleted()
				}
				else{
					self.is_deleting = false
					alert("Couldn't delete the message.")
				}
			}
		}),

		set_deleted: flow(function* () {
			Logger.log("Message:set_deleted", self.text)
			self.text = LANGUAGE.MESSAGE_WAS_DELETED
			self.is_deleting = false
			self.is_deleted = true
		})
	}))
	.views(self => ({
		timestamp(){
			const timestamp = Number(self.timetoken) / 10000
			return dayjs(timestamp).format('DD.MM.YYYY • hh:mm A')
		},
		timestamp_token(){
			return Number(self.timetoken) / 10000
		},
		ago() {
			// We need to convert the timetoken to a UNIX timestamp
			// https://support.pubnub.com/support/solutions/articles/14000043784-how-do-i-convert-the-pubnub-timetoken-
			const timestamp = Number(self.timetoken) / 10000
			const date = dayjs().to(dayjs(timestamp))
			return date;
		},
		full_name(){
			let name = self.uuid
			if(self.first_name !== "" || self.last_name !== ""){
				name = `${self.first_name} ${self.last_name}`
			}
			else{
				const profile = Chat.profiles.find(p => p.id === self.publisher || self.uuid)
				if(profile){
					name = `${profile.first_name} ${profile.last_name}`
				}
			}
			return name
		},
		author(){
			let name = self.publisher !== null ? self.publisher : self.uuid
			if(this.is_you(name)){
				name = `${Chat.current_user.full_name()}`
			}
			else{
				name = self.full_name()
			}
			return name.replace(/\[(.*?)\]/g, "")
		},
		is_you(name = null){
			if(name){
				return name === Chat.current_user.id.toString()
			}
			return self.publisher !== null ? self.publisher === Chat.current_user.id.toString() : self.uuid === Chat.current_user.id.toString()
		},
		is_moderator(){
			return this.author().includes("(Moderator)") || self.is_mod
		}
	}))

export default Message;

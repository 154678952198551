/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from './Logger'

const Loco = types
  .model('Loco', {})
  .actions(self => ({

    central: flow(function* (event, type) {
      Logger.log("Loco:central:choochoo", event, type)
      try{
        window.trackEvent('chat', event, type, 1)
      }
      catch(e){
        Logger.warn("Loco:central:choochoo:error", e)
      }
    }),

  }))
  .create();

export default Loco;

import React from 'react';
import { observer } from 'mobx-react';
import ChatHeader from '../../components/chat_header'
import Nav from '../../stores/Nav'
import EventChat from '../event/event'
import SessionChat from '../session/session'
import QAChat from '../qa/qa'
import Directory from '../directory/directory'
import NoChatModule from './no_chat'

const ChatModule = observer(
	class ChatModule extends React.Component {

		_return_active_tab_section = () => {
			if (Nav.active_tab !== null && Nav.active_tab !== undefined) {
				switch (Nav.active_tab.nickname) {
					case "event":
						return <EventChat />
					case "sessions":
						return <SessionChat />
					case "qa":
						return <QAChat />
					case "directory":
						return <Directory />
					default:
						return null
				}
			}
			return null
		}

		render() {
			if(!Nav.is_offline()){
				return (
					<div className="clip__chat-inner">
						<ChatHeader />
						{this._return_active_tab_section()}
					</div>
				);
			}
			return <NoChatModule />
		}
	}
);
export default ChatModule
import axios from 'axios';
import App from '../stores/App'
import Logger from '../stores/Logger'

export const API_ERROR = 1
export const API_DIRECTORY_ERROR = 2
export const API_PRIVATE_CHAT_CREATE_ERROR = 3
export const API_NONCE_ERROR = 4
export const API_DELETE_ERROR = 5
export const API_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_ENDPOINT : `${window.language}/wp-json/chat/v1`
export const API_DIRECTORY_URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_DIRECTORY_ENDPOINT
export const API_DELETE_URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_DELETE_ENDPOINT
export const BEARER_TOKEN = window.getCookie('token') ? window.getCookie('token') : process.env.REACT_APP_JWT_TOKEN ? process.env.REACT_APP_JWT_TOKEN : '123'
export const SHORT_TOKEN = window.getCookie('token_short') ? window.getCookie('token_short') : process.env.REACT_APP_JWT_TOKEN ? process.env.REACT_APP_JWT_TOKEN : '123'
export const NONCE_URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_NONCE_ENDPOINT
export const REACT_APP_NONCE = process.env.REACT_APP_NONCE
export const USE_LOCAL_NONCE = REACT_APP_NONCE ? true : false

console.log('version_chat', 1)

class PlatformApi {
	
	async get_session() {
		Logger.log('PlatformApi:get_session', API_URL, BEARER_TOKEN)
		if (!BEARER_TOKEN || !SHORT_TOKEN) {
			return API_ERROR
		}
		const session = axios
			.get(`${ API_URL }/session?post=${App.current_post_id}&token=${SHORT_TOKEN}`, {
				headers: {
					'Authorization': `Bearer ${BEARER_TOKEN}`,
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			})
			.then(response => {
				Logger.log("RESPONSE::", response)
				if (response.data) {
					return response.data;
				}
				else {
					App.set_error_message("No data returned")
					return API_ERROR
				}
			})
			.catch(error => {
				Logger.log("ERROR::", error.toString());
				App.set_error_message(error.toString())
				return API_ERROR
			});
		return session;
	}

	async create_private_room(recipient_id) {
		Logger.log('PlatformApi:create_private_room', recipient_id, API_URL);
		const session = axios
			.post(`${ API_URL }/private?token=${SHORT_TOKEN}`, "" ,{
				headers: {
					'Authorization': `Bearer ${BEARER_TOKEN}`,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				params: {
					type: "one-on-one",
					recipient: recipient_id
				}
			})
			.then(response => {
				Logger.log("RESPONSE::", response);
				if (response.data) {
					return response.data;
				}
				else {
					App.set_error_message("Something went wrong")
					return API_PRIVATE_CHAT_CREATE_ERROR
				}
			})
			.catch(error => {
				Logger.log("ERROR::", error.toString())
				App.set_error_message(error.toString())
				return API_PRIVATE_CHAT_CREATE_ERROR
			});
		return session;
	}
	
	async delete_message(timetoken, channel) {
		Logger.log('PlatformApi:delete_message', timetoken, channel);
		const session = axios
			.post(`${ API_DELETE_URL }?token=${SHORT_TOKEN}`, "" ,{
				headers: {
					'Authorization': `Bearer ${BEARER_TOKEN}`,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				params: {
					channel,
					timetoken
				}
			})
			.then(response => {
				Logger.log("RESPONSE::", response);
				if (response.data) {
					return response.data;
				}
				else {
					App.set_error_message("Something went wrong")
					return API_DELETE_ERROR
				}
			})
			.catch(error => {
				Logger.log("ERROR::", error.toString())
				App.set_error_message(error.toString())
				return API_DELETE_ERROR
			});
		return session;
	}
	
	async get_mod_qas(token) {
		Logger.log('PlatformApi:get_mod_qas', API_URL, token)
		const session = axios
			.get(`${ API_URL }/qa/list`, {
				headers: {
					'Authorization': `Bearer ${BEARER_TOKEN}`,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				params: {
					token: token
				}
			})
			.then(response => {
				Logger.log("RESPONSE::", response)
				if (response.data && response.data.channels) {
					return response.data.channels;
				}
				else {
					App.set_error_message("No data returned")
					return API_ERROR
				}
			})
			.catch(error => {
				Logger.log("ERROR::", error.toString());
				App.set_error_message(error.toString())
				return API_ERROR
			});
		return session;
	}

}

export default new PlatformApi()
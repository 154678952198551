import React from 'react';
import { observer } from 'mobx-react';
import ChatModule from './chat/chat';
import LoadingModule from './loading/loading';
import App from '../stores/App'
import ErrorModule from './error/error'

// This is the main starting point, where it all begins.
const AppModule = observer(
	class AppModule extends React.Component {

		componentDidMount = async () => {
			App.hydrate()
		}

		_return_component = () => {
			if (!App.is_loading && App.has_data) {
				return( <ChatModule /> )
			}
			else if(App.is_loading) {
				return( <LoadingModule /> )
			}
			else if(App.error) {
				return( <ErrorModule /> )
			}
			else {
				return null
			}
		}

		render() {
			return (
				<div className="clip clip__chat-container">
					{this._return_component()}
				</div>
			)
		}
	}
);
export default AppModule
import React from 'react';
import { observer } from 'mobx-react';
import ChatMessages from '../../components/chat/messages'
import Nav from '../../stores/Nav'
import App from '../../stores/App'
import ProfileList from '../../components/directory/profile_list'
import DirectoryRoomList from '../../components/directory/room_list'
import SearchList from '../../components/directory/search_list'

const Directory = observer(
	class Directory extends React.Component {

		_render_active_section = () => {
			if (Nav.active_tab?.active_channel !== null) {
				return (
					<div className="clip__chat-message-container chat-profile">
						<ChatMessages channel={Nav.active_tab.get_active_channel()} />
					</div>
				)
			}
			return (
				<div className="clip__chat-list directory">
					<div className="clip__chat-list-rooms">
						{
							App.search_query.length < 2 ? 
								<div>
									<DirectoryRoomList />
									<ProfileList />
								</div>
								: 
								<SearchList />
						}
					</div>
				</div>
			)
		}

		render() {
			return this._render_active_section()
		}
	}

);
export default Directory
import React from 'react';
import { observer } from 'mobx-react';
import LANGUAGE from '../../locales/locale'

const DirectoryListProfile = observer(
	class DirectoryListProfile extends React.Component {

		constructor (props) {
			super(props)
			this.state = {
				is_open: false
			}
		}

		_toggle_open = () => {
			this.setState({
				is_open: !this.state.is_open
			})
		}

		_return_meta = () => {
			if (this.props.profile !== null) {
				return (
					<div>
						{this.props.profile.first_name ? <p><b>{ LANGUAGE.FIRST_NAME }</b> {this.props.profile.first_name}</p> : null}
						{this.props.profile.last_name ? <p><b>{ LANGUAGE.LAST_NAME }</b> {this.props.profile.last_name}</p> : null}
						{this.props.profile.company_name ? <p><b>{ LANGUAGE.COMPANY_NAME }</b> {this.props.profile.company_name}</p> : null}
					</div>
				)
			}
			return null
		}

		render() {
			return (
				<div className="clip__chat-directory-list-profile" data-id={this.props.profile.id}>
					<div className="clip__chat-directory-list-profile-top">
						<div className="clip__chat-directory-list-profile-name" onClick={this._toggle_open}>
							<img src={this.props.profile.profile_photo} alt={this.props.profile.full_name()} />
							<div>
								<h4>{this.props.profile.full_name()}</h4>
								{this.props.profile.company_name !== null ? <h5>{this.props.profile.company_name}</h5> : null}
							</div>
						</div>
						{
							this.props.profile.is_joining ?
								<button>{ LANGUAGE.JOINING }</button>
								:
								<button title="Start chat" onClick={this.props.profile.initiate_chat}>
								<svg width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><path fill="#383c45" d="M31.42,64V55h-3.3a3.11,3.11,0,0,1-3.07-3.15V41.25H27.1V51.86a1,1,0,0,0,1,1h5.35v6.35l6.84-6.35H60.92a1,1,0,0,0,1-1V28.06a1,1,0,0,0-1-1H51.05v-2.1h9.87A3.12,3.12,0,0,1,64,28.06v23.8A3.12,3.12,0,0,1,60.92,55H41.1Z"/><path fill="#48dad0" d="M40.58,47.09l-9.68-9H11.08A3.12,3.12,0,0,1,8,35V11.15A3.12,3.12,0,0,1,11.08,8h32.8A3.11,3.11,0,0,1,47,11.15V35a3.12,3.12,0,0,1-3.07,3.15h-3.3Zm-29.5-37a1,1,0,0,0-1,1.05V35a1,1,0,0,0,1,1H31.69l6.84,6.34V36h5.35a1,1,0,0,0,1-1V11.15a1,1,0,0,0-1-1.05Z"/></svg>
							</button>
						}
						
						<span onClick={this._toggle_open}>
							<svg className={`${this.state.is_open ? "open" : ""}`} xmlns="http://www.w3.org/2000/svg" width="14" height="6" viewBox="0 0 18 8">
								<polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="0 0 8 8 16 0" transform="translate(1)"/>
							</svg>
						</span>
					</div>
					<div className={`clip__chat-directory-list-profile-meta ${this.state.is_open ? "open" : ""}`}>
						{this._return_meta()}
					</div>
				</div>
			)
		}
	}
);
export default DirectoryListProfile
import React from 'react';
import { observer } from 'mobx-react';
import Chat from '../../stores/Chat'
import DirectoryListProfile from './profile_list_item'
import LANGUAGE from '../../locales/locale'

const SearchList = observer(
	class SearchList extends React.Component {

		_return_results = () => {
			if (Chat.filtered_profiles().length > 0) {
				const profiles = Chat.filtered_profiles().map(profile => {
					return <DirectoryListProfile profile={profile} key={profile.id} />
				})
				return (
					<div className="clip__chat-directory-list last">
						<div className="clip__chat-directory-section-title">
							<h4>{ LANGUAGE.SEARCH_RESULTS }</h4>
						</div>
						{profiles}
					</div>
				)
			}
		}

		_render_profiles = () => {
			return (
				<div>
					{this._return_results()}
				</div>
			)
		}

		render() {
			return this._render_profiles()
		}
	}
);
export default SearchList
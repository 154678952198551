import React from 'react';
import { observer } from 'mobx-react';
import ChatMessages from '../../components/chat/messages'
import Nav from '../../stores/Nav'
import ChatHelpText from '../../components/help_text'
import QARoomList from '../../components/qa/room_list'
import RoomList from '../../components/room_list'
import Chat from '../../stores/Chat'

const QaChat = observer(
	class QaChat extends React.Component {

		_render_active_section = () => {
			if (Nav.active_tab !== null && Nav.active_tab.active_channel !== null) {
				return (
					<div className="clip__chat-message-container">
						{!Chat.current_user.is_moderator() ? <ChatHelpText /> : null}
						<ChatMessages channel={Nav.active_tab.get_active_channel()} />
					</div>
				)
			}
			return (
				<div className="clip__chat-list">
				{
					Chat.current_user.is_moderator() ? <QARoomList /> : <RoomList />
				}
				</div>
			)
		}

		render() {
			return this._render_active_section()
		}
	}

);
export default QaChat
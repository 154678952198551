/*eslint require-yield: "off"*/
import { types, flow, applySnapshot } from 'mobx-state-tree';
import Chat from '../Chat'
import Nav from '../Nav'
import PlatformApi, { API_PRIVATE_CHAT_CREATE_ERROR } from '../../api/PlatformApi'
import Logger from '../Logger'

const Profile = types
  .model('Profile', {
    id: types.identifierNumber,
    role: types.maybeNull(types.string),
    first_name: types.maybeNull(types.string, ""),
    last_name: types.maybeNull(types.string, ""),
    is_joining: types.optional(types.boolean, false),
    profile_photo: types.maybeNull(types.string),
    company_name: types.maybeNull(types.string)
  })
  .actions(self => ({
    initiate_chat: flow(function* () {
      Logger.log("Profile:initiate_chat", self.id, self.is_joining)
      if (!self.is_joining) {
        self.is_joining = true
        // First we want to check if we already have a chat with the same profile started
        if (self.existing_channel() !== undefined) {
          // We want to go ahead and just enter that channel
          Nav.active_tab.set_active_channel(self.existing_channel())
        }
        else {
          // We want to go ahead and create a new room
          const created_chat = yield PlatformApi.create_private_room(self.id)
          if (created_chat !== API_PRIVATE_CHAT_CREATE_ERROR) {
            Logger.log("Profile:initiate_chat:created", created_chat)
            // yield App.add_room(created_chat.room)
          }
        }
        self.is_joining = false
      }
    }),

    hydrate_meta: flow(function* (profile) {
      // Now we want to set profile data too
      for (const [ key, value ] of Object.entries(profile.public_meta)) {
        const profile_info_item = {
          key: key,
          value: value[ 0 ]
        }
        self.profile_data.push(profile_info_item)
      }
    }),

    update_profile: flow(function* (profile) {
      const new_profile_object = {
        id: self.id,
        role: profile.role,
        first_name: profile.first_name[ 0 ],
        last_name: profile.last_name[ 0 ],
        profile_photo: profile.profile_photo[ 0 ],
        is_joining: self.is_joining
      }
      applySnapshot(self, new_profile_object)
      self.hydrate_meta(profile)
    }),

  }))
  .views(self => ({
    is_rep() {
      return self.role === "representative"
    },
    is_moderator() {
      return self.role === "moderator"
    },
    full_name() {
      return `${self.first_name} ${self.last_name}`
    },
    existing_channel() {
      const channels = Chat.channels_by_type("one_on_one")
      if (channels) {
        return channels.find(c => c.recipient_profile()?.id === self.id)
      }
      return null
    }
  }))

export default Profile;

import 'react-app-polyfill/ie11';
import './utils/polyfill';
import './utils/cookies';
import './utils/snapshots';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import App from './modules/App'

const chat_element = document.querySelector('[react-clip="chat-v2"]')

if (chat_element) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    chat_element
  );
}

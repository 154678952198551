/*eslint require-yield: "off"*/
import { types } from 'mobx-state-tree';
import Chat from './../Chat'

const Group = types
  .model('Group', {
    id: types.identifier,
    title: types.maybeNull(types.string),
  })
  .views(self => ({
    channels(){
      const channels = Chat.channels_by_type("qa").filter(qa => qa.group === self.id)
      if(Chat.current_user.is_rep() || Chat.current_user.is_moderator()){
        return channels.filter(channel => channel.messages()?.length >= 1).sort((a, b) => { return new Date(b.last_updated_at()) - new Date(a.last_updated_at()) })
      }
      return channels
    },
    nice_title (){
      if(self.title === "" || self.title === null){
        return self.id.replace(/&amp;/gm, "&").replace(/&#8211;/gm, "-")
      }
      return self.title.replace(/&amp;/gm, "&").replace(/&#8211;/gm, "-")
    }
  }))

export default Group;

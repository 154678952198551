import React from 'react';
import { observer } from 'mobx-react';
import App from '../../stores/App'

const ErrorModule = observer(
	class ErrorModule extends React.Component {

		constructor (props) {
			super(props)
			this.state = {
				countdown: 8
			}
		}

		componentDidMount = () => {
			setInterval(() => {
				this.setState({
					countdown: this.state.countdown - 1 >= 0 ? this.state.countdown - 1 : 0
				})
			}, 1000)
		}

		render() {
			return (
				<div className="clip__chat-inner clip__chat-error">
					<p>Error: {App.error}</p>
					<span>Trying again in {this.state.countdown} {this.state.countdown > 1 ? "seconds" : "second"}...</span>
				</div>
			);
		}
	}
);
export default ErrorModule
/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from '../Logger'
import Chat from '../Chat'
import Nav from '../Nav'
import LANGUAGE from '../../locales/locale'

const Tab = types
	.model('Tab', {
		title: types.identifier,
		nickname: types.optional(types.string, ""), // nickname so we can ref it
		current_level: types.optional(types.number, 0), // ZERO BASED, up to 1
		multi_level: types.optional(types.boolean, false), // FALSE if only one chatroom present
		active_channel: types.maybeNull(types.string), // We want to have an active room available to us
		index: types.optional(types.number, 0) // We want to get tabs sorted by their index
	})
	.actions(self => ({

		set_level: flow(function* (level = 0) {
			Logger.log("Tab:set_level", level)
			self.current_level = level
			if (self.multi_level && level === 0 && self.active_channel !== "") {
				self.set_active_channel()
			}
		}),

		set_active_channel: flow(function* (channel = null) {
			Logger.log("Tab:set_active_channel", channel)
			self.active_channel = channel ? channel.name : null
			if (self.active_channel) {
				// We also want to join the channel if we haven't done so!
				if (!channel.joined) {
					channel.join().then(() => {
						channel.initiate_message_scroll()
						if (channel.unread_messages_count > 0 && Nav.active_tab === self) {
							channel.reset_unread_count()
						}
					})
				}
				else {
					channel.initiate_message_scroll()
					if (channel.unread_messages_count > 0 && Nav.active_tab === self) {
						channel.reset_unread_count()
					}
				}
			}
		}),
		
		set_multi_level: flow(function* (is_multi) {
			Logger.log("Tab:set_multi_level", is_multi)
			self.multi_level = is_multi
		}),

	}))
	.views(self => ({
		get_active_channel() {
			return self.active_channel !== null ? Chat.channels.find(channel => channel.name === self.active_channel) : null
		},
		get_channels() {
			// This gets the rooms depending on the tab
			switch (self.nickname) {
				case "event":
					return Chat.channels.filter(channel => channel.type === "event")
				case "sessions":
					return Chat.channels.filter(channel => channel.type === "session")
				case "qa":
					const qa_channels = Chat.channels.filter(channel => channel.type === "qa")
					if(Chat.current_user.is_rep() || Chat.current_user.is_moderator()){
						return qa_channels.filter(channel => channel.messages()?.length >= 1)
					}
					return qa_channels
				case "directory":
				return Chat.channels.filter(channel => channel.type === "one_on_one").sort((a, b) => { return new Date(b.last_updated_at()) - new Date(a.last_updated_at()) })
				default:
					return null
			}
		},
		get_unread_count() {
			let count = 0
			if (this.get_channels() !== null) {
				this.get_channels().forEach(room => {
					count += room.unread_messages_count
				})
			}
			return count
		},
		name() {
			if (self.nickname === 'directory') {
				return "D"
			}
			else if(self.nickname === 'event'){
				return LANGUAGE.EVENT
			}
			else if(self.nickname === 'sessions'){
				return LANGUAGE.SESSIONS
			}
			else if(self.nickname === 'qa'){
				return LANGUAGE.QA
			}
			return self.title
		},
		has_multi_levels() {
			return this.get_channels().length > 1
		},
	}))

export default Tab;

/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import PlatformApi, { API_ERROR } from '../api/PlatformApi'
import Logger from './Logger'
import Chat from './Chat'
import Nav from './Nav'

let is_trying_again = false

const App = types
	.model('App', {
		is_loading: types.optional(types.boolean, false),
		error: types.optional(types.string, ""),
		current_post_id: types.maybeNull(types.number),
		has_data: types.optional(types.boolean, false),
		search_query: types.optional(types.string, "")
	})
	.actions(self => ({

		hydrate: flow(function* () {
			Logger.log("App:hydrate")
			self.is_loading = true
			is_trying_again = false
			
			const is_active = document.querySelector('.user-active');
			Logger.log("App:hydrate:user_is_active_class", is_active)
			if(!is_active){
				// Check if we are inactive first
				const in_active = document.querySelector('.user-inactive')
				if(in_active){
					setTimeout(() => {
						App.set_error_message("No user is active yet... Please wait.", true, true)
					}, 500)
				}
				else{
					setTimeout(() => {
						App.set_error_message("No user is active", true)
					}, 500)
				}
				return
			}
			
			App.load_current_page()
			const session = yield PlatformApi.get_session()
			if (session.publish_key && session.subscribe_key && session !== API_ERROR) {
				Logger.log("APP:short_token_and_session", session)
				const success = yield Chat.hydrate(session)
				if(success){
					Logger.log("APP:hydrate:success", success)
					Nav.hydrate()
					App.set_is_loading()
					self.has_data = true
				}
			}
			else {
				// Something went wrong
				self.set_error_message("Something went wrong")
				Logger.warn("Couldn't retrieve a session!")
				self.set_is_loading(false)
			}
		}),

		set_is_loading: flow(function* (loading) {
			Logger.log("App:set_is_loading", loading)
			self.is_loading = loading
		}),

		set_error_message: flow(function* (message, force_retry = false, skip_clear = false) {
			Logger.log("App:set_error_message", message, force_retry)
			self.error = message
			self.is_loading = false
			if (!is_trying_again) {
				if(!skip_clear){
					setTimeout(() => {
						App.clear_session_storage()
					}, 500)
				}
				// Now might be a good time to also allow for a set timeout to try again
				setTimeout(() => {
					App.hydrate()
				}, 8000)
				is_trying_again = true
			}
			else if(force_retry){
				if(!skip_clear){
					setTimeout(() => {
						App.clear_session_storage()
					}, 500)
				}
				setTimeout(() => {
					App.hydrate()
				}, 8000)
				is_trying_again = true
			}
		}),

		clear_session_storage: flow(function* () {
			Logger.log("App:clear_session_storage", )
			// As we have an error, it might be best to remove any session storage
			sessionStorage.removeItem("chat-nav")
		}),

		load_current_page: flow(function* () {
			Logger.log("App:load_current_page")
			const post_id = document.getElementsByTagName("body")[ 0 ].getAttribute('data-post')
			Logger.log("App:load_current_page:post_id", post_id)
			if (post_id !== null && post_id !== undefined) {
				self.current_post_id = Number(post_id)
			}
		}),
		
		update_global_unread_count: flow(function* () {
			Logger.log("App:update_global_unread_count")
			if (Chat.channels.length > 0) {
				let unread_count = 0
				Chat.channels.forEach((channel) => {
					unread_count += channel.unread_messages_count
				})
				Logger.log("App:update_global_unread_count:COUNT", unread_count)
				const unread_counter = document.querySelector('[data-unread-message-count]')
				if (unread_counter !== null) {
					Logger.log("App:update_global_unread_count:FOUND_CONTAINER", unread_counter)
					if (unread_count > 0) {
						unread_counter.innerHTML = unread_count
					}
					else {
						unread_counter.innerHTML = ""
					}
				}
			}
		}),
		
		set_search_query: flow(function* (event) {
			Logger.log("App:set_search_query", event.target.value)
			self.search_query = event.target.value
		}),

	}))
	.views(self => ({
		
	}))
	.create();

export default App;

import React from 'react';
import { observer } from 'mobx-react';
import ChatMessages from '../../components/chat/messages'
import Nav from '../../stores/Nav'

const EventChat = observer(

	class EventChat extends React.Component {
		render() {
			return (
				<div className="clip__chat-message-container">
					{
						Nav.active_tab.get_active_channel() !== null ?
						<ChatMessages channel={Nav.active_tab.get_active_channel()} />
						: null
					}
				</div>
			);
		}
	}

);
export default EventChat
/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from './Logger'
import Channel from './models/Channel'
import Message from './models/Message'
import User from './models/User'
import Nav from './Nav'
import Group from './models/Group'
import Profile from './models/Profile'
import PubNub from 'pubnub'
import Fuse from 'fuse.js'
import App from './App'
import Loco from './Loco'
import PlatformApi, { API_ERROR } from './../api/PlatformApi'

let pubnub = null

const Chat = types
  .model('Chat', {
    has_client: types.optional(types.boolean, false),
    did_error: types.optional(types.boolean, false),
    channels: types.optional(types.array(Channel), []),
    messages: types.optional(types.array(Message), []),
    current_user: types.maybeNull(User),
    groups: types.optional(types.array(Group), []),
    profiles: types.optional(types.array(Profile), []),
    directory_ready: types.optional(types.boolean, false),
    site_id: types.maybeNull(types.number)
  })
  .actions(self => ({

    hydrate: flow(function* (session) {
      Logger.log("PUBNUB:hydrate", session)
      const user = yield self.set_user(session)
      if(user && user.can_chat()){
        Logger.log("PUBNUB:hydrate:user", user)
        // Now that we have a user, let's go ahead and do what we need to
        pubnub = new PubNub({
          publishKey: session.publish_key,
          subscribeKey: session.subscribe_key,
          authKey: session.user_hash,
          uuid: user.id,
          keepAlive: true
        })
        Logger.log("PUBNUB:pubnub", pubnub)
        if(pubnub !== null && session.channels?.length){
          self.has_client = true
          // Now we also want to check if there is a session
          if(session.site_id !== undefined && session.site_id !== null){
            self.site_id = session.site_id
          }
          yield self.setup(session.channels)
          return true
        }
        else{
          self.set_error("CHAT:error")
          App.set_error_message("Chat failed to load.")
          return false
        }
      }
      else{
        self.set_error("CHAT:error - user not active")
        App.set_error_message("Chat failed to load.")
        return false
      }
    }),
    
    set_user: flow(function* (session) {
      Logger.log("Chat:set_user", session)
      self.current_user = User.create(session)
      return self.current_user
    }),

    client: () => {
      // Logger.log("PUBNUB:client", pubnub)
      if(pubnub !== null){
        return pubnub
      }
      else{
        self.hydrate()
        return pubnub
      }
    },

    set_error: flow(function* (error, section = null) {
      Logger.warn("PubNub:set_error", error, section)
      self.did_error = true
    }),
    
    setup: flow(function* (channels) {
      Logger.log("PUBNUB:setup", self.client(), channels)
      yield self.add_listeners()
      // const where = yield self.client().whereNow({uuid: self.client().uuid})
      // Logger.log("PUBNUB:setup:whereNOW:", where)
      // We want to create channels now
      if(self.site_id !== null){
        yield self.hydrate_directory()
      }
      self.listen_for_session_change()
      if(self.current_user.is_moderator()){
        channels = yield PlatformApi.get_mod_qas(self.current_user.token)
      }
      if(channels !== null){
        let channels_array = []
        channels.forEach((channel) => {
          const new_channel = Channel.create({
            name: channel.channel,
            type: channel.type,
            channel_name: channel.name,
            group: channel.group,
            group_name: channel.name,
            visible: channel.visible !== undefined && channel.visible !== null ? channel.visible : true,
            user: channel.user !== undefined && channel.user !== null ? channel.user : null
          })
          // First we need to check if only one exists!
          const existing_channel = self.find_channel(new_channel.name)
          if (existing_channel === undefined && new_channel.channel_name !== "") {
            self.channels.push(new_channel)
            // We only want to subscribe to all if we are a MOD or REP.
            // Otherwise, we also want to subscribe to everything except sessions
            if((self.current_user.is_rep() || self.current_user.is_moderator()) || channel.type !== 'session'){
              channels_array.push(channel.channel)
            }
            if(channel.group !== undefined && channel.group !== null){
              Chat.add_group(channel)
            }
          }
        })
        // Now we want to set all channels to join at once
        // SUPERPOWERS 💥
        if(self.current_user.is_moderator()){
          channels_array.forEach((channel) => {
            Chat.client().subscribe({
              channels: [channel],
              withPresence: false
            })
          })
          // Now we want to reload our list
          Chat.set_moderator_qas_reload()
        }
        else{
          Chat.client().subscribe({
            channels: channels_array,
            withPresence: false
          })
        }
      }
    }),
    
    add_group: flow(function* (channel) {
      // Logger.log("PubNub:add_group", channel)
      const group = Group.create({
        id: channel.group,
        title: channel.name
      })
      const existing_group = self.groups.find(g => g.id === group.id)
      if(existing_group === undefined){
        // Logger.log("PubNub:add_group:added", group)
        self.groups.push(group)
      }
    }),
    
    add_listeners: flow(function* () {
      Logger.log("PUBNUB:add_listeners", self.client())
      // We want to setup event listeners and handle accordingly
      self.client().addListener({
        status: function(status) {
          Logger.log("PUBNUB:setup:event:status", status)
          if (status.category === 'PNConnectedCategory') {
            Logger.log("PUBNUB:setup:event:status:PNConnectedCategory", status)
            if(status.affectedChannels){
              Chat.handle_channels_join(status.affectedChannels)
            }
          }
          else{
            Logger.log("PUBNUB:setup:event:status:PNConnectedCategory:BAHHHHHH", status?.errorData?.payload?.channels[0])
            if(status?.errorData?.payload?.channels[0] !== null && status?.errorData?.payload?.channels[0] !== undefined){
              Chat.client().unsubscribe({
                channels: [status?.errorData?.payload?.channels[0]]
              })
            }
          }
        },
        message: function(message) {
          Logger.log("PUBNUB:setup:event:message", message)
          if(message?.message?.new_channel !== undefined){
            Chat.handle_private_join(message)
          }
          else{
            Chat.add_message(message)
          }
        },
        presence: function(presence) {
          Logger.log("PUBNUB:setup:event:presence", presence)
        },
        signal: function(signal) {
          Logger.log("PUBNUB:setup:event:signal", signal)
        },
        objects: (object_event) => {
          Logger.log("PUBNUB:setup:event:objects", object_event)
        },
        messageAction: function(message_action) {
          Logger.log("PUBNUB:setup:event:messageAction", message_action, message_action.data, message_action?.data?.type)

          if (message_action?.data?.type === 'deletion') {
            // messageTimetoken
            const channel = self.find_channel(message_action?.channel)
            Logger.log("PUBNUB:setup:event:messageAction channel", message_action?.channel)
            channel.remove_message(message_action?.data?.messageTimetoken)
          }
        }
      })
    }),
    
    listen_for_session_change: flow(function* () {
      Logger.log("PubNub:listen_for_session_change")
      window.addEventListener('session_change', (event) => {
        if(event?.detail !== null){
          Chat.handle_session_change(event.detail)
        }
      })
    }),
    
    handle_session_change: flow(function* (session) {
      Logger.log("PubNub:handle_session_change", session)
      if(session !== null){
        // First we will try and find it
        const existing_channel = self.find_channel(session.channel)
        if(existing_channel === undefined){
          let name = session.channel
          if(session.type === 'qa'){
            name = `${name}${Chat.current_user.id.toString()}`
          }
          const new_channel = Channel.create({
            name: name,
            type: session.type,
            channel_name: session.name
          })
          self.channels.push(new_channel)
          yield Nav.check_and_add_tabs()
          // Now we need to switch tabs and go to the correct session
          Nav.set_tab_and_session(new_channel)
        }
        else{
          // We have an existing channel so switch to it
          yield Nav.check_and_add_tabs()
          // Now we need to switch tabs and go to the correct session
          Nav.set_tab_and_session(existing_channel)
        }
      }
    }),
    
    handle_channels_join: flow(function* (channels) {
      Logger.log("PubNub:handle_channels_join", channels)
      channels.forEach((channel) => {
        const existing_channel = self.find_channel(channel)
        Logger.log("PubNub:handle_channels_join:channels", channel, existing_channel)
        if(existing_channel !== undefined){
          existing_channel.set_joined()
        }
      })
    }),
    
    get_initial_messages: flow(function* (channel) {
      // Logger.log("PubNub:get_initial_messages", channel)
      const messages = yield self.client().fetchMessages(
        {
          channels: [channel],
          count: 25
        }
      )
      // Logger.log("PubNub:get_initial_messages:messages", messages)
      if(messages && messages.channels){
        const channel_keys = Object.keys(messages.channels)
        // Logger.log("PubNub:get_initial_messages:messages:channel", channel_keys)
        channel_keys.forEach((key) => {
          Logger.log("PubNub:get_initial_messages:messages:channel:key", key, messages.channels[key], decodeURIComponent(key))
          const channel_messages = messages.channels[key]
          if(channel_messages && channel_messages.length){
            channel_messages.forEach((message) => {
              message.channel = decodeURIComponent(key)
              Chat.add_message(message, true)
            })
          }
        })
      }
    }),
    
    add_message: flow(function* (message, is_initial = false) {
      // Logger.log("PubNub:add_message", message, message?.message?.text)
      // Let's check if it's a correctly formatted message first
      if(message?.message?.text && message?.channel){
        // Now check if it's not already on the stack
        const existing_message = self.messages.find(m => m.timetoken === message.timetoken)
        if(existing_message === undefined){
          const message_model = Message.create({
            timetoken: message?.timetoken,
            channel: message?.channel,
            uuid: message?.uuid,
            text: message?.message?.text,
            first_name: message?.message?.first_name,
            last_name: message?.message?.last_name,
            publisher: message?.publisher,
            is_mod: message?.message?.is_mod
          })
          self.messages.push(message_model)
          const channel = self.channels.find(channel => channel.name === message.channel)
          if (Nav?.active_tab !== null && Nav?.active_tab?.active_channel !== null && Nav?.active_tab?.get_active_channel()?.name === message.channel) {
            // We want to scroll the container down
            Nav.active_tab.get_active_channel().initiate_message_scroll()
          }
          else if(!is_initial){
            // We now want to set a unread count against the channel in question
            Logger.log("PubNub:message:is_you", message_model.is_you())
            if(channel?.allow_notification && !message_model.is_you()){
              channel.set_unread_count()
            }
          }
          if(!is_initial && channel !== null && channel !== undefined){
            let message_type = "receive_message"
            if(message_model.is_you()){
              message_type = "send_message"
            }
            Loco.central(message_type, channel.type)
          }
        }
        
      }
    }),
    
    hydrate_directory: flow(function* (next = null) {
      Logger.log("PubNub:hydrate_directory")
      const data = yield pubnub.objects.getAllUUIDMetadata({include: {customFields: true, totalCount: true}, page: {next: next}});
      Logger.log("PUBNUB:DATA:ALL_UUID", data)
      if(data?.data){
        data.data.forEach((profile) => {
          // Logger.log("PUBNUB:DATA:Profile", profile) */}
          const profile_object = Profile.create({
            id: Number(profile.id),
            first_name: profile.custom?.first_name || profile.name?.split(" ")[0],
            last_name: profile.custom?.last_name || profile.name?.split(" ")[1],
            company_name: profile.custom?.company_name || null,
            profile_photo: profile.custom?.image || null,
            role: profile.custom?.role || null
          })
          const existing_profile = self.profiles.find(p => p.id === profile_object.id)
          if(existing_profile === undefined){
            // Logger.log("PubNub:add_profile:added", profile_object)
            self.profiles.push(profile_object)
          }
        })
        self.directory_ready = true
        // Now we want to go ahead and set an auto-reload
        if(data.totalCount > data.data.length){
          Logger.log("PubNub:directory:count:total:data", data.totalCount, data.data.length, data.previous, data.next, next)
          // Now we want to go ahead and create a paged call to the directory
          if(next === null && data.next !== undefined && data.prev === undefined){
            Logger.log("PubNub:directory:is_going_next-initial", data.next, next, data.prev)
            Chat.hydrate_directory(data.next)
          }
          else if (next !== null && data.prev !== undefined && self.profiles.length < data.totalCount){
            Logger.log("PubNub:directory:is_going_next", data.next, next, data.prev)
            setTimeout(() => {
              Chat.hydrate_directory(data.next)
            }, 300)
          }
          else{
            setTimeout(() => {
              Chat.hydrate_directory()
            }, 90000)
          } 
        }
        else{
          setTimeout(() => {
            Chat.hydrate_directory()
          }, 90000)
        } 
      }
    }),
    
    handle_private_join: flow(function* (data) {
      Logger.log("PubNub:handle_private_join", data)
      const new_channel = Channel.create({
        name: data?.message?.channel_name,
        type: data?.message?.type,
        channel_name: data?.message?.channel_name
      })
      const existing_channel = self.find_channel(new_channel.name)
      if (existing_channel === undefined) {
        self.channels.push(new_channel)
        new_channel.join()
        Chat.hydrate_directory()
      }
    }),
    
    set_moderator_qas_reload: flow(function* () {
      Logger.log("PubNub:set_moderator_qas_reload")
      setTimeout(() => {
        Chat.handle_moderator_qas_reload()
      }, 30000)
    }),
    
    handle_moderator_qas_reload: flow(function* () {
      Logger.log("PubNub:handle_moderator_qas_reload")
      const channels = yield PlatformApi.get_mod_qas(self.current_user.token)
      if(channels !== null && channels !== API_ERROR){
        let channels_array = []
        channels.forEach((channel) => {
          const new_channel = Channel.create({
            name: channel.channel,
            type: channel.type,
            channel_name: channel.name,
            group: channel.group,
            group_name: channel.name,
            visible: channel.visible !== undefined && channel.visible !== null ? channel.visible : true,
            user: channel.user !== undefined && channel.user !== null ? channel.user : null
          })
          // First we need to check if only one exists!
          const existing_channel = self.find_channel(new_channel.name)
          if (existing_channel === undefined && new_channel.channel_name !== "") {
            self.channels.push(new_channel)
            // We only want to subscribe to all if we are a MOD or REP.
            // Otherwise, we also want to subscribe to everything except sessions
            if((self.current_user.is_rep() || self.current_user.is_moderator()) || channel.type !== 'session'){
              channels_array.push(channel.channel)
            }
            if(channel.group !== undefined && channel.group !== null){
              Chat.add_group(channel)
            }
          }
        })
        channels_array.forEach((channel) => {
          Chat.client().subscribe({
            channels: [channel],
            withPresence: false
          })
        })
      }
      Chat.set_moderator_qas_reload()
    }),
  }))
  .views(self => ({
    find_channel(name){
      if(self.channels === null || self.channels === undefined){
        return undefined
      }
      else{
        return self.channels.find(channel => channel.name === name)
      }
    },
    channels_by_type(type){
      if(self.channels?.length > 0){
        if(type === "event"){
          return self.channels.find(channel => channel.type === type)
        }
        else{
          return self.channels.filter(channel => channel.type === type)
        }
      }
      return null
    },
    messages_by_channel(channel){
      return self.messages.filter(message => message.channel === channel)
    },
    rep_profiles() {
      return this.filtered_profiles().filter(p => p.role === "representative")
    },
    participant_profiles() {
      return this.filtered_profiles().filter(p => p.role !== "representative")
    },
    filtered_profiles() {
      let profiles = self.profiles?.filter(p => p.id !== self.current_user.id && p.full_name() !== " ")
      if (App.search_query !== "" && App.search_query.length >= 2 && self.profiles !== null) {
        // Due to a bug I need to create a pure array for search terms
        let results = []
        profiles.forEach(p => {
          let search_terms = []
          if(p.first_name !== null && p.first_name !== undefined){
            search_terms.push(p.first_name.toLowerCase())
          }
          if(p.last_name !== null && p.last_name !== undefined){
            search_terms.push(p.last_name.toLowerCase())
          }
          if(p.company_name !== null && p.company_name !== undefined){
            search_terms.push(p.company_name.toLowerCase())
          }
          p.search_terms = search_terms
        })
        const fuse = new Fuse(profiles, {
          keys: [ 'search_terms', 'first_name', 'last_name', 'company_name' ],
          includeScore: true,
          isCaseSensitive: true,
          minMatchCharLength: 4
        });
        results = fuse.search(App.search_query.toLowerCase())
        let found_profiles = []
        results.forEach(result => {
          if (result.score <= .5) {
            found_profiles.push(result.item)
          }
        })
        if(found_profiles > 0){
          return found_profiles
        }
        
        results = profiles.filter(p => p.first_name.toLowerCase().includes(App.search_query.toLowerCase()) || p.last_name.toLowerCase().includes(App.search_query.toLowerCase()) || (p.company_name !== null && p.company_name !== undefined && p.company_name.toLowerCase().includes(App.search_query.toLowerCase() || p.full_name.toLowerCase().includes(App.search_query.toLowerCase()))))
        if(results.length === 0){
          const search_terms = App.search_query.split(" ")
          Logger.log("TERMS", search_terms)
          let split_results = []
          search_terms.forEach((term) => {
            if(term.length === 0){
              return
            }
            const new_results = profiles.filter(p => p.first_name.toLowerCase().includes(term.toLowerCase()) || p.last_name.toLowerCase().includes(term.toLowerCase()) || (p.company_name !== null && p.company_name !== undefined && p.company_name.toLowerCase().includes(term.toLowerCase() || p.full_name.toLowerCase().includes(term.toLowerCase()))))
            if(new_results){
              new_results.forEach((result) => {
                const existing = split_results.find(r => r === result)
                if(existing === undefined){
                  split_results.push(result)
                }
              })
            }
          })
          results = split_results
        }
        Logger.log("FOUND ENTRIES", results, App.search_query.length)
        return results
      }
      return profiles
    },
  }))
  .create();

export default Chat;

import React from 'react'
import { observer } from 'mobx-react'
import ChatInput from './input'
import LoadingModule from '../../modules/loading/loading'
import Chat from './../../stores/Chat'
import LANGUAGE from '../../locales/locale'

const ChatMessages = observer(
	class ChatMessages extends React.Component {

		constructor (props) {
			super(props)
			this.state = {
				counter: 0,
				copied: false
			}
			this.message_list = React.createRef()
			this.interval_timer = null
		}
		
		_copy_message = (text, author) => {
			var el = document.createElement('textarea');
			el.value = `${author}: ${text}`;
			// Set non-editable to avoid focus and move outside of view
			el.setAttribute('readonly', '');
			el.style = {position: 'absolute', left: '-9999px'};
			document.body.appendChild(el);
			el.select();
			// Copy text to clipboard
			document.execCommand('copy');
			this.setState({copied: true}, () => {
				setTimeout(() => {
					this.setState({copied: false})
				}, 3000)
			})
			// Remove temporary element
			document.body.removeChild(el);
		}

		_return_messages = () => {
			const messages = this.props.channel?.messages().map((message) => {
				const is_you_class = message.is_you() ? "you" : ""
				const is_moderator_class = message.is_moderator() ? "mod" : ""
				return (
					<div className={`clip__chat-message ${is_you_class} ${is_moderator_class}`} data-id={message.timetoken} key={message.timetoken}>
						<div className="clip__chat-message-header">
							<span>{message.author()}</span>
							<time dateTime={message.timestamp()}>{message.ago(this.state.counter)}</time>
						</div>
						{
							message.is_deleting ? <p>...</p> : <p>{message.text}</p>
						}
						{
							(Chat.current_user.is_moderator() || Chat.current_user.is_rep()) && !message.is_deleted ?
							<div className="clip__chat-message-controls">
								<button onClick={message.remove_message}>{ LANGUAGE.DELETE }</button>
								<button onClick={() => this._copy_message(message.text, message.author())}>{ LANGUAGE.COPY }</button>
							</div> 
							: null
						}
					</div>
				)
			})
			return messages?.length === 0 ? <div className="clip__chat-message"><p><em>{ LANGUAGE.NO_MESSAGES_TO_SHOW }</em></p></div> : messages
		}

		componentDidMount = () => {
			this.props.channel.set_message_container(this.message_list)
			// Let's also go ahead and try and join if not already joined
			if(!this.props.channel.joined){
				this.props.channel.join()
			}
			// Now we want to set a timer
			this.interval_timer = setInterval(() => {
				this.setState({
					counter: this.state.counter + 1
				})
			}, 1000)
		}
		
		componentWillUnmount = () => {
			clearInterval(this.interval_timer)
		}

		render() {
			return (
				<div className="clip__chat-messages">
					{
						this.props.channel?.is_loading ?
							<LoadingModule message={"Loading chat..."} />
						: 
						<div className="clip__chat-messages-list" ref={this.message_list}>
							{this._return_messages()}
						</div>
					}
					{this.state.copied ? <span className={"clip__chat-messages-copy"}>{ LANGUAGE.COPIED }</span> : null}
					{!this.props.channel?.is_loading ? <ChatInput />  : null}
				</div>
			);
		}
	}
);
export default ChatMessages
import React from 'react';
import { observer } from 'mobx-react';
import LoopIcon from './../../assets/icons/loading.gif';

const LoadingModule = observer(
	class LoadingModule extends React.Component {

		render() {
			const message = this.props.message ? this.props.message : ""
			return (
				<div className="clip__chat-inner">
					<div className="clip__chat-inner-loading">
						<img alt="Loading" src={LoopIcon} style={{ width: 40, height: 40 }} />
						<p>{message}</p>
					</div>
				</div>
			);
		}
	}
);
export default LoadingModule
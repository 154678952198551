/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';

const Logger = types
	.model('Logger', {})
	.actions(() => ({
		log: flow(function* (arg_one, arg_two, arg_three, arg_four) {
			if (process.env.REACT_APP_ENABLE_CHAT_LOGGER !== undefined && process.env.REACT_APP_ENABLE_CHAT_LOGGER === "true") {
				if (arg_four !== undefined) {
					console.log("CHAT LOG:", arg_one, arg_two, arg_three, arg_four)
				}
				else if (arg_three !== undefined) {
					console.log("CHAT LOG:", arg_one, arg_two, arg_three)
				}
				else if (arg_two !== undefined) {
					console.log("CHAT LOG:", arg_one, arg_two)
				}
				else {
					console.log("CHAT LOG:", arg_one)
				}
			}
		}),
		warn: flow(function* (arg_one, arg_two, arg_three, arg_four) {
			if (process.env.REACT_APP_ENABLE_CHAT_LOGGER !== undefined && process.env.REACT_APP_ENABLE_CHAT_LOGGER === "true") {
				if (arg_four !== undefined) {
					console.warn("CHAT LOG:", arg_one, arg_two, arg_three, arg_four)
				}
				else if (arg_three !== undefined) {
					console.warn("CHAT LOG:", arg_one, arg_two, arg_three)
				}
				else if (arg_two !== undefined) {
					console.warn("CHAT LOG:", arg_one, arg_two)
				}
				else {
					console.warn("CHAT LOG:", arg_one)
				}
			}
		}),
	}))
	.create();

export default Logger;

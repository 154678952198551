import React from 'react';
import { observer } from 'mobx-react';

const ChatHelpText = observer(
	class ChatHelpText extends React.Component {

		render() {
			return null
			// return (
			// 	<div className="clip__chat-help">
			// 		<p>Info Lorem ipsum dolor sit amet, diam consetetur sea etc sadipscing elitr sed di nonumy eirmod tempore invid.</p>
			// 		<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
			// 	</div>
			// );
		}
	}
);
export default ChatHelpText
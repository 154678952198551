/*eslint require-yield: "off"*/
import { types, flow, applySnapshot, destroy } from 'mobx-state-tree';
import Tab from './models/Tab'
import Logger from './Logger'
import Chat from './Chat'
import LANGUAGE from '../locales/locale'

const Nav = types
	.model('Nav', {
		active_tab: types.maybeNull(types.reference(Tab)),
		tabs: types.maybeNull(types.array(Tab), [])
	})
	.actions(self => ({

		hydrate: flow(function* () {
			Logger.log("Nav:hydrate")
			// const chat_nav_storage = sessionStorage.getItem("chat-nav")
			// if (chat_nav_storage) {
			// 	Logger.log("NAV:hydrate:has_chat_nav_storage", chat_nav_storage)
			// 	applySnapshot(self, JSON.parse(chat_nav_storage))
			// 	Logger.log("Nav:hydrate::tabs", self.tabs)
			// 	self.check_and_add_tabs()
			// 	return
			// }
			// Check the amount of tabs we should have
			let tabs = []
			if (Nav.has_directory_tab()) {
				tabs.push(Tab.create({ title: LANGUAGE.ATTENDEES, nickname: "directory", multi_level: true, index: 0 }))
			}
			if (Nav.has_event_tab()) {
				tabs.push(Tab.create({title: LANGUAGE.EVENT, nickname: "event", multi_level: false, index: 0, active_channel: Chat.channels_by_type("event").name }))
			}
			if (Nav.has_sessions_tab()) {
				tabs.push(Tab.create({title: LANGUAGE.SESSIONS, nickname: "sessions", multi_level: Chat.channels_by_type("session").length > 1, index: 2}))
			}
			if (Nav.has_qa_tab()) {
				tabs.push(Tab.create({title: LANGUAGE.QA, nickname: "qa", multi_level: Chat.channels_by_type("qa").length > 1, index: 3}))
			}
			console.log('tabs', tabs)
			self.tabs = tabs
			self.active_tab = self.tabs[0]
			Logger.log("Nav:hydrate::tabs", self.tabs)
			if(self.active_tab?.nickname === "qa" && self.is_single_qa()){
				Logger.log("Nav:check_and_add_tabs:is_single")
				self.active_tab.set_active_channel(Chat.channels_by_type("qa")[0])
			}
		}),

		set_active_tab: flow(function* (tab, ignore_switch = false) {
			Logger.log("Nav:set_active_tab", tab.title)
			if (tab === self.active_tab && !ignore_switch) {
				tab.set_level(0)
			}
			self.active_tab = tab
			if (self.active_tab !== null && self.active_tab.active_channel !== null) {
				// We want to try and scroll to the bottom of the message list
				self.active_tab.get_active_channel().initiate_message_scroll()
				// TODO: Reset message count
				self.active_tab.get_active_channel().reset_unread_count()
			}
			else if(self.active_tab !== null){
				// Let's check if it's a session tab, if so we want to set it active
				if(tab.nickname === 'sessions' && tab.get_channels().length === 1){
					Logger.log("Tab:set_active_channel:is_one_session")
					tab.set_active_channel(tab.get_channels()[0])
				}
				else if(tab.nickname === 'qa' && tab.get_channels().length === 1){
					Logger.log("Tab:set_active_channel:is_one_session")
					tab.set_active_channel(tab.get_channels()[0])
				}
				else if(tab.nickname === 'qa'){
					Nav.check_and_add_tabs()
				}
			}
		}),

		set_tabs_shown: flow(function* (show = true) {
			Logger.log("Nav:set_tabs_shown", show)
			self.tabs_shown = show
		}),
		
		set_tab_and_session: flow(function* (channel) {
			Logger.log("Nav:set_tab_and_session", channel)
			let type = "session"
			if(channel.type !== "session"){
				type = channel.type
			}
			const session_tab = self.tab_by_name(type)
			Logger.log("Nav:set_tab_and_session:tab", session_tab, type)
			if(session_tab !== undefined){
				self.active_tab = session_tab
				session_tab.set_active_channel(channel)
			}
		}),
		
		check_and_add_tabs: flow(function* () {
			Logger.log("Nav:check_and_add_tabs")
			if (Nav.has_directory_tab() && Nav.tab_by_name("directory") === undefined) {
				self.tabs.push(Tab.create({ title: LANGUAGE.ATTENDEES, nickname: "directory", multi_level: true, index: 0 }))
			}
			if (Nav.has_event_tab() && Nav.tab_by_name("event") === undefined) {
				self.tabs.push(Tab.create({title: LANGUAGE.EVENT, nickname: "event", multi_level: false, index: 1 }))
			}
			if (Nav.has_sessions_tab() && Nav.tab_by_name("sessions") === undefined) {
				self.tabs.push(Tab.create({title: LANGUAGE.SESSIONS, nickname: "sessions", multi_level: Chat.channels_by_type("session").length > 1, index: 2}))
			}
			if (Nav.has_qa_tab() && Nav.tab_by_name("qa") === undefined) {
				self.tabs.push(Tab.create({title: LANGUAGE.QA, nickname: "qa", multi_level: Chat.channels_by_type("qa").length > 1, index: 3}))
			}
			if(self.active_tab === null){
				self.active_tab = self.tabs[0]
			}
			if(self.active_tab?.nickname === "qa" && self.is_single_qa() && !Chat.current_user.is_moderator()){
				Logger.log("Nav:check_and_add_tabs:is_single")
				self.active_tab.set_active_channel(Chat.channels_by_type("qa")[0])
			}
			else{
				const qa_tab = self.tabs.find(tab => tab.nickname === "qa")
				if(qa_tab){
					if(Chat.channels_by_type("qa").length){
						qa_tab.set_multi_level(Chat.channels_by_type("qa").length > 1)
					}
					else{
						self.active_tab = self.tabs[0]
						destroy(qa_tab)
					}
				}
			}
		}),

	}))
	.views(self => ({
		has_event_tab() {
			return Chat.channels_by_type("event")?.visible
		},
		has_sessions_tab() {
			return Chat.channels_by_type("session") !== null && Chat.channels_by_type("session").length > 0
		},
		has_qa_tab() {
			return Chat.channels_by_type("qa") !== null && Chat.channels_by_type("qa").length > 0
		},
		has_directory_tab() {
			return Chat.profiles !== null && Chat.profiles.length > 0
		},
		is_only_qa() {
			return !this.has_event_tab() && !this.has_sessions_tab() && this.has_qa_tab()
		},
		is_single_qa() {
			return this.has_qa_tab() && Chat.channels_by_type("qa") !== null && Chat.channels_by_type("qa").length === 1
		},
		has_multi_qas() {
			return this.has_qa_tab() && Chat.channels_by_type("qa").length > 1
		},
		tab_by_name(name) {
			return self.tabs.filter(tab => tab.nickname === name)[ 0 ]
		},
		tabs_by_index() {
			return self.tabs.sort((a, b) => { return a.index - b.index })
		},
		is_offline(){
			return !self.has_event_tab() && !self.has_sessions_tab() && !self.has_qa_tab() && !self.has_directory_tab()
		}
	}))
	.create();

export default Nav;

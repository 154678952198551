/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from '../Logger'
import Chat from './../Chat'
import Nav from './../Nav'
import App from './../App'
import LANGUAGE from '../../locales/locale'

const Channel = types
	.model('Channel', {
		name: types.identifier,
		type: types.string,
		joined: types.optional(types.boolean, false),
		is_loading: types.optional(types.boolean, true),
		unread_messages_count: types.optional(types.number, 0),
		current_message: types.optional(types.string, ""),
		can_send_message: types.optional(types.boolean, false),
		allow_notification: types.optional(types.boolean, true),
		group: types.maybeNull(types.string),
		group_name: types.maybeNull(types.string),
		channel_name: types.maybeNull(types.string),
		is_sending_message: types.optional(types.boolean, false),
		visible: types.optional(types.boolean, true),
		user: types.maybeNull(types.string)
	})
	.actions(self => {
		
		let messages_container = null
		let scroll_counter = 0

		const join = flow(function* () {
			Logger.log("Channel:join", self.name)
			// Let's subscribe to the channel
			if(!self.joined){
				Chat.client().subscribe({
					channels: [self.name],
					withPresence: false
				})
				// Logger.log("Channel:join:subscribe")
			}
		})

		const set_error = flow(function* (error, section = null) {
			Logger.warn("Channel:set_error", self.name, error, section)
		})
		
		const set_joined = flow(function* () {
			// Logger.log("Channel:set_joined")
			self.joined = true
			self.is_loading = false
			// Now that we have joined and got the signal, let's get our initial messages
			self.get_initial_messages()
		})
		
		const get_initial_messages = flow(function* (show_unread = false) {
			// Logger.log("Channel:get_initial_messages")
			yield Chat.get_initial_messages(self.name)
			if((Chat.current_user.is_rep() || Chat.current_user.is_moderator()) && show_unread){
				self.check_unread_count()
			}
		})

		const set_current_message = flow(function* (event) {
			Logger.log("CHANNEL:set_current_message", self.name, event.target.value)
			self.current_message = event.target.value
			if (self.current_message !== "") {
				self.can_send_message = true
			}
			else {
				self.can_send_message = false
			}
		})

		const send_current_message = flow(function* () {
			Logger.log("CHANNEL:send_current_message", self.name, self.current_message, self.is_sending_message)
			if(self.is_sending_message){
				return
			}
			self.is_sending_message = true
			const sent_message = yield Chat.client().publish(
				{
					channel: self.name,
					message: {text: self.current_message, first_name: Chat.current_user.first_name, last_name: Chat.current_user.last_name, is_mod: Chat.current_user.is_moderator()}
				}
			)
			Logger.log("CHANNEL:send_current_message:sent", sent_message)
			self.is_sending_message = false
			self.current_message = ""
			self.can_send_message = false
		})
		
		const set_message_container = flow(function* (ref) {
			Logger.log("Channel:set_message_container", ref)
			messages_container = ref
		})
		
		const scroll_message_container = flow(function* () {
			Logger.log("Channel:scroll_message_container")
			if (messages_container !== null && messages_container.current !== null) {
				messages_container.current.scrollTop = messages_container.current.scrollHeight
			}
		})

		const initiate_message_scroll = flow(function* (immediate = false) {
			Logger.log("Channel:initiate_message_scroll")
			if (messages_container !== null && messages_container.current !== null) {
				if (immediate) {
					messages_container.current.scrollTop = messages_container.current.scrollHeight
				}
				else {
					setTimeout(() => {
						messages_container.current.scrollTop = messages_container.current.scrollHeight
					}, 100)
				}
			}
			else if (Nav.active_tab.active_channel === self.name) {
				if (scroll_counter <= 50) {
					setTimeout(() => {
						self.initiate_message_scroll(true)
					}, 25)
					scroll_counter += 1
				}
			}
		})
		
		const toggle_notifications = flow(function* () {
			Logger.log("ROOM:toggle_notifications")
			self.allow_notification = !self.allow_notification
			if (!self.allow_notification) {
				// Let's reset this to ZERO
				self.reset_unread_count()
			}
		})
		
		const set_unread_count = flow(function* () {
			Logger.log("Channel:set_unread_count")
			if (self.allow_notification) {
				self.unread_messages_count += 1
				App.update_global_unread_count()
			}
		})
		
		const reset_unread_count = flow(function* () {
			Logger.log("Channel:reset_unread_count")
			self.unread_messages_count = 0
			App.update_global_unread_count()
		})
		
		const check_unread_count = flow(function* () {
			Logger.log("Channel:check_unread_count")
			if(Chat.messages_by_channel(self.name)?.length > 0){
				self.unread_messages_count = 1
				App.update_global_unread_count()
			}
		})

		const remove_message = flow(function* (timetoken) {
			Logger.log("Channel:remove_message", timetoken)

			const messages = Chat.messages_by_channel(self.name)
			console.log('Channel:remove_message messages', messages)
			const existing_message = messages.find(m => m.timetoken === timetoken)
			console.log('Channel:remove_message existing_message', existing_message)
			if (existing_message !== undefined) {
				existing_message.set_deleted()
			}
		})

		return {
			join,
			set_error,
			set_joined,
			send_current_message,
			set_current_message,
			get_initial_messages,
			set_message_container,
			scroll_message_container,
			initiate_message_scroll,
			toggle_notifications,
			set_unread_count,
			reset_unread_count,
			check_unread_count,
			remove_message
		}
	})
	.views(self => ({
		messages(){
			return Chat.messages_by_channel(self.name)
		},
		updated_at() {
			const messages = Chat.messages_by_channel(self.name)
			return messages?.length > 0 ? messages[messages.length - 1].timestamp() : LANGUAGE.NO_MESSAGES_TO_SHOW
		},
		last_updated_at(){
			const messages = Chat.messages_by_channel(self.name)
			return messages?.length > 0 ? messages[messages.length - 1].timestamp_token() : null
		},
		nice_name() {
			// We want to get back the nice name of the room
			if(self.type === "one_on_one"){
				return self.channel_name
			}
			if((Chat.current_user.is_rep() || Chat.current_user.is_moderator()) && self.type === "qa"){
				if(self.group !== null){
					// Let's try and get the profile
					const group_name = self.name.replace("[", "").replace("]", "").replace(self.group.toString(), "").replace("-", "").replace("_", "")
					const profile = Chat.profiles.find(p => p.id === Number(group_name))
					if (profile) {
						return profile.full_name()
					}
					else{
						if(self.user !== null && Chat.profiles.length === 0){
							return self.user
						}
						return group_name.replace(/&amp;/gm, "&").replace(/&#8211;/gm, "-")
					}
				}
				return self.name.replace(/&amp;/gm, "&").replace(/&#8211;/gm, "-")
			}
			else if(self.type === 'qa'){
				return self.channel_name.replace(/&amp;/gm, "&").replace(/&#8211;/gm, "-")
			}
			const word_split = self.name.split(" ", 1)
			if (word_split.length) {
				return word_split[ 0 ].replace(/-/g, " ").replace(/&amp;/gm, "&").replace(/&#8211;/gm, "-")
			}
			return self.name.replace(/\[(.*?)\]/g, "").replace(/&amp;/gm, "&").replace(/&#8211;/gm, "-")
		},
		has_profile() {
			return self.type === 'one_on_one'
		},
		recipient_profile() {
			if (this.has_profile()) {
				// Check against the profile
				const name = `${self.name}_`
				const first_profile_id = name.match(/-(.*?)_/gm)// We want to get the first matching ID
				const second_profile_id = name.match(/_(.*?)_/gm)
				// Logger.log("CHANNEL:recipient_profile", first_profile_id, second_profile_id)
				// We should have two bits
				if (first_profile_id?.length && second_profile_id?.length) {
					const first_profile = Number(first_profile_id[0].replace(/_/g, '').replace("-", ""))
					const second_profile = Number(second_profile_id[0].replace(/_/g, ''))
					if (first_profile !== Chat.current_user.id) {
						const profile = Chat.profiles.find(p => p.id === first_profile)
						if (profile) {
							return profile
						}
					}
					else if (second_profile !== Chat.current_user.id) {
						const profile = Chat.profiles.find(p => p.id === second_profile)
						if (profile) {
							return profile
						}
					}
				}
			}
			return null
		},
	}))

export default Channel;

/*eslint require-yield: "off"*/
import { types } from 'mobx-state-tree';

const User = types
	.model('User', {
		id: types.identifierNumber,
		status: types.optional(types.string, ""),
		role: types.optional(types.string, ""),
		initials: types.optional(types.string, ""),
		first_name: types.optional(types.string, ""),
		last_name: types.optional(types.string, ""),
		token: types.optional(types.string, ""),
	})
	.views(self => ({
		can_chat() {
			return (self.role === "representative" || self.role === "participant" || self.role === "moderator") && self.status === "active"
		},
		is_rep() {
			return self.role === "representative"
		},
		is_moderator() {
			return self.role === "moderator"
		},
		full_name() {
			return `${self.first_name} ${self.last_name}`
		}
	}))

export default User;

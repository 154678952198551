import React from 'react';
import { observer } from 'mobx-react';
import Chat from '../../stores/Chat'
import DirectoryListProfile from './profile_list_item'
import LANGUAGE from '../../locales/locale'
import LazyLoad from 'react-lazyload';

const ProfileList = observer(
	class ProfileList extends React.Component {

		_return_participants = () => {
			if (Chat.participant_profiles()?.length > 0) {
				const profiles = Chat.participant_profiles().map(profile => {
					return <LazyLoad height={1} offset={100} key={profile.id} scrollContainer={".clip__chat-list-rooms"}><DirectoryListProfile profile={profile} key={profile.id} /></LazyLoad>
				})
				return (
					<div className="clip__chat-directory-list last">
						<div className="clip__chat-directory-section-title">
							<h4>{ LANGUAGE.ATTENDEES }</h4>
						</div>
						{profiles}
					</div>
				)
			}
		}

		_return_reps = () => {
			if (Chat.rep_profiles()?.length > 0) {
				const profiles = Chat.rep_profiles().map(profile => {
					return <DirectoryListProfile profile={profile} key={profile.id} />
				})
				return (
					<div className="clip__chat-directory-list">
						<div className="clip__chat-directory-section-title">
							<h4>{ LANGUAGE.REPRESENTATIVES }</h4>
						</div>
						{profiles}
					</div>
				)
			}
		}

		_render_profiles = () => {
			return (
				<div>
					{this._return_reps()}
					{this._return_participants()}
				</div>
			)
		}

		render() {
			return this._render_profiles()
		}
	}
);
export default ProfileList
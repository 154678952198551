import React from 'react';
import { observer } from 'mobx-react';
import Nav from '../stores/Nav'
import ChatActions from './chat/actions'
import DirectoryHeader from './directory/header'

const ChatHeader = observer(
	class ChatHeader extends React.Component {

		_render_tabs = () => {
			if(Nav.tabs !== null){
				const tabs = Nav.tabs_by_index().map((tab) => {
					const is_active = tab === Nav.active_tab
					const unread_count = tab.get_unread_count()
					return (
						<div className={`clip__chat-header-tabs-tab ${tab.nickname}`} key={tab.title}>
							<div className="clip__chat-header-tabs-tab-group">
								<button onClick={() => Nav.set_active_tab(tab)} className={is_active ? "active" : ""}>
									{
										tab.nickname === 'directory' ?
											<svg width="30" enableBackground="new 0 0 48 48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="m47 1v46h-46v-46zm1-1h-48v48h48z" fill="#fff" opacity="0"/><g fill="none"><path d="m32.1556 34.9825h-16.1556" stroke="#2dd5c9" strokeMiterlimit="10" strokeWidth="2.0911"/><path d="m29 32v-8.1327c0-1.5835-1.3776-2.8673-3.0769-2.8673h-3.8462c-1.6993 0-3.0769 1.2838-3.0769 2.8673v8.1327" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.0911"/><path d="m21.7708 12.985v2.0301c0 1.0946 1.0007 1.985 2.2297 1.985s2.2287-.8904 2.2287-1.985v-2.0301c0-1.0946-.9997-1.985-2.2287-1.985s-2.2297.8904-2.2297 1.985z" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.0911"/><path d="m37.25 14.985v2.0301c0 1.0946 1.01 1.985 2.2505 1.985s2.2495-.8904 2.2495-1.985v-2.0301c0-1.0946-1.009-1.985-2.2495-1.985s-2.2505.8904-2.2505 1.985z" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.1008"/><path d="m6.2708 14.985v2.0301c0 1.0946 1.0007 1.985 2.2297 1.985s2.2287-.8904 2.2287-1.985v-2.0301c0-1.0946-.9997-1.985-2.2287-1.985s-2.2297.8904-2.2297 1.985z" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.0911"/><path d="m44 36.0083v-10.0716c0-1.6173-1.3521-2.9284-3.0201-2.9284h-2.9599c-1.6679 0-3.02 1.3744-3.02 2.9917v10.0083" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/><path d="m0 0h48v48h-48z" opacity="0"/><path d="m13 36.0083v-10.0716c0-1.6173-1.3521-2.9284-3.0201-2.9284h-2.9598c-1.668 0-3.0201 1.3744-3.0201 2.9917v10.0083" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.0911"/></g></svg>
										: tab.name()
									}
								</button>
								<span className={`clip__chat-header-tabs-tab-count ${unread_count > 0 ? "shown" : ""}`}>{unread_count}</span>
							</div>
						</div>
					)
				})
				return (
					<div className="clip__chat-header-tabs">
					{tabs}
					</div>
				)
			}
		}

		render() {
			return (
				<div className="clip__chat-header">
					{this._render_tabs()}
					{Nav.active_tab !== null && Nav.active_tab.active_channel !== null && Nav.active_tab.nickname !== 'directory' ? <ChatActions /> : null} 
					{Nav.active_tab !== null && Nav.active_tab.nickname === 'directory' ? <DirectoryHeader /> : null}
				</div>
			)
		}
	}
);
export default ChatHeader
import React from 'react';
import { observer } from 'mobx-react';
import Nav from '../../stores/Nav'
import LANGUAGE from '../../locales/locale'

const ChatInput = observer(
	class ChatInput extends React.Component {

		constructor (props) {
			super(props)
			this.input_container = React.createRef()
		}

		check_for_enter = (e) => {
			if (e.key === "Enter" && Nav.active_tab.get_active_channel().can_send_message) {
				Nav.active_tab.get_active_channel().send_current_message()
			}
		}

		check_and_set_textarea_height = () => {
			if (this.input_container.current !== null) {
				var height = this.input_container.current.scrollHeight
				if (height < 40) {
					this.input_container.current.style.height = 'auto'
				}
				else {
					this.input_container.current.style.height = `${this.input_container.current.scrollHeight}px`
				}
			}
		}

		render() {
			if (!Nav?.active_tab?.get_active_channel()?.is_loading) {
				const placeholder_text = LANGUAGE.TYPE_MESSAGE_PLACEHOLDER
				return (
					<div className="clip__chat-messages-input">
						<input onKeyPress={(e) => this.check_for_enter(e)} type="text" onKeyUp={this.check_and_set_textarea_height} ref={this.input_container} autoFocus placeholder={placeholder_text} onChange={(event) => Nav.active_tab.get_active_channel().set_current_message(event)} value={Nav.active_tab.get_active_channel().current_message} />
						<button onClick={Nav.active_tab.get_active_channel().send_current_message} disabled={!Nav.active_tab.get_active_channel().can_send_message} title={"Send"}>
							<svg xmlns="http://www.w3.org/2000/svg" width="6" height="8" viewBox="0 0 6 8">
								<polyline fill="none" stroke="#FFF" strokeLinecap="square" strokeLinejoin="round" points="0 0 4 4 8 0" transform="rotate(-90 4.5 3.5)"/>
							</svg>
						</button>
					</div>
				);
			}
			else if (Nav?.active_tab?.get_active_channel()?.is_loading) {
				return (
					<div className="clip__chat-messages-input">
						<p>{ LANGUAGE.LOADING }</p>
					</div>
				)
			}
			return null
		}
	}
);
export default ChatInput